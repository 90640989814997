$(function () {
  $('a[href*="#"].inline_link').not('[href="#"]').not('[href="#0"]').on('click',function (e) {
    if (
      location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
      &&
      location.hostname == this.hostname
    ) {
      let headerHeight = $('.header').height();
      let scrollH = headerHeight + 44;
      if ($(window).width() > 1024) {
        scrollH = headerHeight
      };
      var target = $(this.hash);
      target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
      if (target.length) {
        $('html, body').animate({
          scrollTop: target.offset().top - (scrollH)
        }, 1000, function () {

          var $target = $(target);
          $target.focus();
          if ($target.is(":focus")) {
            return false;
          } else {
            $target.attr('tabindex', '-1');
            $target.focus();
          };
        });
      }
    }
  });

  // courses_menu menu
  $(window).scroll(function () {
    let windowHeight = $(window).height();
    let headerHeight = $('.header').height();
    let bannerHeight = $('.courses_banner').height();
    let breadcrumbHeight = $('.breadcrumb').height();
    let articleBanner = $('.article_show_banner').height();
    let articleHeight = $('.article_show_wrapper').height();
    let articleRelatedHeight = $('.article_show_related_items').height();
    let articleCanScroll = headerHeight + breadcrumbHeight + articleBanner + articleHeight - (windowHeight - (windowHeight - articleRelatedHeight ));
    if ($(window).scrollTop() >= (headerHeight + bannerHeight - 44)) {
      $('#courses_menu').addClass('fixed');
    } else {
      $('#courses_menu').removeClass('fixed');
    };

    if ($(window).scrollTop() <= (headerHeight + breadcrumbHeight + articleBanner)) {
      $('#article_related_items').removeClass('fixed');
    } else {
      $('#article_related_items').addClass('fixed');
      if ($(window).scrollTop() >= articleCanScroll) {
        $('#article_related_items').removeClass('fixed');
        $('#article_related_items').addClass('fixed_bottom');
      }else {
        $('#article_related_items').removeClass('fixed_bottom');
      }
    };

  });

  // case
  $('[data-case-btn]').on("click", function () {
    const changImg = $(this).attr('data-case-btn');
    $(this).parents('[data-case-show]').attr('data-case-show', changImg);
  });

  // QA手風琴組件
  // $('.accordion').find('[data-accordion-link]').on('click', function () {
  //   $(this).toggleClass('active');
  //   $(this).parents('.accordion').toggleClass('active');
  //   $(this).parents('.accordion').find('.accordion_content').slideToggle("fast");
  // });

  // $('.accordion').first().find('[data-accordion-link]').trigger('click');

  // menu
  let LM_Width = $('.list_menu').width();
  let LM_ItemsWidth = $('.list_menu_items').outerWidth();
  if (LM_ItemsWidth > LM_Width) {
    $('.list_menu_nav[data-nav=next]').addClass('show');
    $('.list_menu').scroll(function() {
      if ($(this).scrollLeft() >= 10) {
        $(this).find('[data-nav=prev]').addClass('show');
      }else {
        $(this).find('[data-nav=prev]').removeClass('show');
      }
      if ($(this).scrollLeft() >= LM_ItemsWidth - LM_Width) {
        $(this).find('[data-nav=next]').removeClass('show');
      }else {
        $(this).find('[data-nav=next]').addClass('show');
      }
    });
  }
  $('.list_menu_nav').on('click', function() {
    let nav = $(this).attr('data-nav');
    if (nav == 'prev') {
      $('.list_menu').animate({ scrollLeft: '-=' + LM_Width/3 }, 300);
    }else {
      $('.list_menu').animate({ scrollLeft: '+=' + LM_Width / 3 }, 300);
    }
  });

  // editor table
  $('.custom_editor').find('table').wrap('<div class="table_responsive"></div>');

  // map
  $('.contact_us_map_container').scrollLeft(100);
});
