$(function () {
  $('.owl-carousel.courses_items').owlCarousel({
    responsiveClass: true,
    dots: true,
    responsive: {
      0: {
        items: 2,
      },
      1000: {
        items: 3,
      }
    },

  });
});


$(function () {
  $('.doctors_show_other .owl-carousel').owlCarousel({
    margin: 0,
    responsiveClass: true,
    dots: true,
    responsive: {
      0: {
        items: 2,
      },
      600: {
        items: 3,
      },
      768: {
        items: 4,
      },
      900: {
        items: 5,
      },
      1280: {
        items: 6,
      }
    },

  });
});
