$(function () {
  // 文章選單切換
  $(`[data-header-action]`).on( "click", function(e) {
    const hrefTemp = $(this).attr('data-header-action');
    $(this).addClass('show');
    $(this).siblings().removeClass('show');
    $('[data-header-group="' + hrefTemp + '"]').addClass('show');
    $('[data-header-group="' + hrefTemp + '"]').siblings().removeClass('show');
  });

  $('#mobileMenuBtn').on('click', function () {
    $(this).toggleClass('active');
    $('.header_menu_container').toggleClass('active');
  });

  $(`[data-mobile-action="tab"]`).on("click", function (e) {
    const hrefTemp = $(this).attr('data-header-menu');
    const menuContent = $(this).find('.header_dropdown_container').clone();
    $(this).addClass('show');
    $(this).siblings().removeClass('show');
    $('[data-mobile-tab').removeClass('show');
    $('[data-mobile-tab="' + hrefTemp + '"]').addClass('show');
    $('.header_menu_mobile_dropdown').html(menuContent);
  });

  $('[data-header-menu=surgery]').trigger('click');

  $(`[data-contact]`).find('.float_contact_btn').on('click', function() {
    $(this).siblings('.item_content').addClass('contact_show');
    $('.float_contact').addClass('contact_show');
  })
  $('[data-contact]').find('.item_close').on('click', function() {
    $(this).parents('.item_content').removeClass('contact_show');
    $('.float_contact').removeClass('contact_show');
  })
});

$(function () {
  // Google 轉換追蹤
  $(document).ready(function() {
    $('#contact-us-phone-btn').on('click', function(e) {
      gtag('event', 'click', {
        'event_category' : 'button',
        'event_label' : 'contact-us-phone'
      });
      fbq('track', 'Contact');
      
      window.uetq = window.uetq || [];
      window.uetq.push('event', 'contact-us-phone', {});
    });
  });

  $(document).ready(function() {
    $('#contact-us-phone-link').on('click', function(e) {
      gtag('event', 'click', {
        'event_category' : 'button',
        'event_label' : 'contact-us-phone'
      });
      fbq('track', 'Contact');

      window.uetq = window.uetq || [];
      window.uetq.push('event', 'contact-us-phone', {});
    });
  });

  $(document).ready(function() {
    $('#contact-us-line-btn').on('click', function(e) {
      gtag('event', 'click', {
        'event_category' : 'button',
        'event_label' : 'contact-us-line'
      });
      fbq('track', 'Contact');

      window.uetq = window.uetq || [];
      window.uetq.push('event', 'contact-us-line', {});
    });
  });

  $(document).ready(function() {
    $('#contact-us-line-link').on('click', function(e) {
      gtag('event', 'click', {
        'event_category' : 'button',
        'event_label' : 'contact-us-line'
      });
      fbq('track', 'Contact');

      window.uetq = window.uetq || [];
      window.uetq.push('event', 'contact-us-line', {});
    });
  });

  $(document).ready(function() {
    $('#contact-us-fb-btn').on('click', function(e) {
      gtag('event', 'click', {
        'event_category' : 'button',
        'event_label' : 'contact-us-fb'
      });
      fbq('track', 'Contact');

      window.uetq = window.uetq || [];
      window.uetq.push('event', 'contact-us-fb', {});
    });
  });

  $(document).ready(function() {
    $('#contact-us-fb-link').on('click', function(e) {
      gtag('event', 'click', {
        'event_category' : 'button',
        'event_label' : 'contact-us-fb'
      });
      fbq('track', 'Contact');

      window.uetq = window.uetq || [];
      window.uetq.push('event', 'contact-us-fb', {});
    });
  });

  $(document).ready(function() {
    $('#contact-us-event-bra-fat').on('click', function(e) {
      gtag('event', 'click', {
        'event_category' : 'button',
        'event_label' : 'contact-us-event-bra-fat'
      });
      fbq('track', 'Contact');

      window.uetq = window.uetq || [];
      window.uetq.push('event', 'contact-us-event-bra-fat', {});
    });
  });
});
