$(function () {
  $('.index_main_banner .owl-carousel').owlCarousel({
    items: 1,
    loop: true,
    slideSpeed: 6000,
    autoplay: true,
    nav: false,
    dots: true,
    thumbs: true,
    thumbsPrerendered: true
  });

  $(`[data-index-case-btn]`).on("click", function() {
    const changImg = $(this).attr('data-index-case-btn');
    $(this).parents('.index_case_item').attr('data-index-case-img', changImg);
  });
});
