// CSS Style
import 'sweetalert2/dist/sweetalert2.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'styles/client'

import Rails from "@rails/ujs"
import WOW from 'wow.js';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'owl.carousel';
import 'owl.carousel2.thumbs';
import 'client/base';
import 'client/header_footer';
import 'client/home';
import 'client/doctors';

Rails.start();

window.jQuery = $;
window.$ = $;
window.Swal = Swal;

$(function () {
  var wow = new WOW({
    boxClass: 'wow',
    animateClass: 'animated',
    offset: 0,
    mobile: true,
    live: false,
    scrollContainer: null
  });
  wow.init();

});

